import React from "react"
import loadable from "@loadable/component"
import Modal from "react-modal"
import { LandingPageConfig } from "../config/config-data"
const Header = loadable(() => import(/* webpackPreload: true */ "../components/header"))
const Hero = loadable(() => import(/* webpackPreload: true */ "../components/FrontPage/hero"))
const CounterBar = loadable(() => import(/* webpackPreload: true */ "../components/counterbar"))
const FrontSeo = loadable(() => import(/* webpackPreload: true */ "../components/FrontPage/frontseo"))
const LeadForm = loadable(() => import(/* webpackPrefetch: true */ "../components/leadForm"))
const Footer = loadable(() => import(/* webpackPrefetch: true */ "../components/footer"))
const FooterBar = loadable(() => import(/* webpackPrefetch: true */ "../components/footerbar"))
const Video = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/video"))
const SocialProof = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/socialproof"))
const Faq = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/faq"))
const CallToAction = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/calltoaction"))
const About = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/about"))
const Benefits = loadable(() => import(/* webpackPrefetch: true */ "../components/FrontPage/benefits"))
const Special = loadable(() => import(/* webpackPrefetch: true */ "../config/bone/special"))
const Animation = loadable(() => import(/* webpackPrefetch: true */ "../config/bone/animation"))
const PopUp = loadable(() => import(/* webpackPrefetch: true */ "../components/popup"))
const Content = loadable(() => import(/* webpackPrefetch: true */ "../config/bone/content"))
import "../styles/global.css"


// const FrontSeo = loadable(() => import(/* webpackPreload: true */ "../components/FrontPage/frontseo"))
// const Header = loadable(() => {return (LandingPageConfig.header_option && import(/* webpackPreload: true */ "../components/header"))})
// const Hero = loadable(() => {return (LandingPageConfig.hero_section && import(/* webpackPreload: true */ "../components/FrontPage/hero"))})
// const CounterBar = loadable(() => {return (LandingPageConfig.counter_bar_option && import(/* webpackPreload: true */ "../components/counterbar"))})
// const LeadForm = loadable(() => {return (LandingPageConfig.modal_option && import(/* webpackPrefetch: true */ "../components/leadForm"))})
// const Footer = loadable(() => {return (LandingPageConfig.footer_option && import(/* webpackPrefetch: true */ "../components/footer"))})
// const FooterBar = loadable(() => {return (LandingPageConfig.footer_bar_option && import(/* webpackPrefetch: true */ "../components/footerbar"))})
// const SocialProof = loadable(() => {return (LandingPageConfig.socialproof_section && import(/* webpackPrefetch: true */ "../components/FrontPage/socialproof"))})
// const Faq = loadable(() => {return (LandingPageConfig.faq_section && import(/* webpackPrefetch: true */ "../components/FrontPage/faq"))})
// const CallToAction = loadable(() => {return (LandingPageConfig.calltoaction_section && import(/* webpackPrefetch: true */ "../components/FrontPage/calltoaction"))})
// const About = loadable(() => {return (LandingPageConfig.about_section && import(/* webpackPrefetch: true */ "../components/FrontPage/about"))})
// const Benefits = loadable(() => {return (LandingPageConfig.benefits_section && import(/* webpackPrefetch: true */ "../components/FrontPage/benefits"))})
// const Special = loadable(() => {return (LandingPageConfig.special_section && import(/* webpackPrefetch: true */ "../config/bone/special"))})
// const Animation = loadable(() => {return (LandingPageConfig.animation_section && import(/* webpackPrefetch: true */ "../config/bone/animation"))})
// import (/* webpackPreload: true */ '../styles/global.css');

const IndexPage = () => {

  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modalIsOpen]);


  return(
    <>
      {LandingPageConfig.header_option &&
      <Header />
      }
      {LandingPageConfig.hero_section &&
      <Hero ModalState={openModal} GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.counter_bar_option &&
      <CounterBar />
      }
      <FrontSeo />
      {LandingPageConfig.footer_bar_option &&
      <FooterBar ModalState={openModal} />
      }
      {LandingPageConfig.animation_section &&
      <Animation GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.video_section &&
      <Video GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.socialproof_section &&
      <SocialProof GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.benefits_section &&
      <Benefits GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.special_section &&
      <Special GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.about_section &&
      <About GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.faq_section &&
      <Faq GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.calltoaction_section &&
      <CallToAction ModalState={openModal} GradientAnimation={modalIsOpen} />
      }
      {LandingPageConfig.footer_option &&
      <Footer />
      }
      {LandingPageConfig.popup_option &&
      <PopUp><Content /></PopUp>
      }
      {LandingPageConfig.modal_option &&
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={LandingPageConfig.modal_outside_close ? false : true}
        contentLabel="Lead Modal"
      >
        <svg className="Modal_Close_Button" alt="Modal Close Button" onClick={closeModal} title="Close Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
        </svg>
        <LeadForm />
      </Modal>
      }
    </>
  );
};

export default IndexPage